.partners{
    padding: 50px;
    height: auto;
}

.partners h1{
    margin-bottom: 40px;
}

.partnersImg{
    justify-content: center;
    text-align: center;
}

.partnersImg img{
    width: 40vw;
    min-width: 320px;
}