.aboutus{
    padding: 50px 80px;
   
    height: auto;
}

@media (max-width: 768px) {
    .aboutus{
        padding: 60px 0px;
        height: auto;

    }
  }