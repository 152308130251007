.Footer{
    background-color: #000;
    height: auto;
   
    
}

.Help li{
    text-decoration-line: none;
    padding:"10px 0px";
    font-family: var( --titleFont);
}
.Help li:hover{
   background-color: rgb(123, 0, 194);
   border-radius: 5px;
}
.Help h3{
    color: white;
    font-size: 200%;
    text-align: left;
    font-family: var( --titleFont);
    margin:"0px 0px 30px 0px"
}
.About li{
    text-decoration-line: none;
    padding:"10px 0px";

}
.About li:hover{
   background-color: rgb(123, 0, 194);
   border-radius: 5px;
}

.About h3{
    color: white;
    font-size: 200%;
    text-align: left;
    font-family: var( --titleFont);
    margin:"0px 0px 30px 0px";
    
}
.About div {
    margin: 60px 0px ;
}
.About i {
    color: white;
    font-size: 25px;
    margin: 0px 10px;
}

.About i:hover {
    color: rgb(123, 0, 194);
 
}

.Coming h3{
    color: white;
    font-size: 200%;
    text-align: left;
    font-family: var( --titleFont);
    margin:"0px 0px 30px 0px"
}
.Privacy h3{
    color: white;
    font-size: 250%;
    text-align: center;
    font-family: var( --titleFont);
    margin:"0px 0px 30px 0px"
}
.Privacy div {
    text-align: center;
    margin: 10px 0px 30px 0px;
}
.Privacy a{
    text-decoration: none;
    margin: 0px 20px;
    color: gray;
    font-family: var( --titleFont);
}

.patro{
    width: 200px;
    margin: 10px 20px;
}

.patrodiv{
    width: 240px;
}

.comingdiv{
    margin: 50px 0px 0px 0px;
}

@media (max-width: 576px) {
    .Coming h3 , .About h3, .Help h3{
        text-align: center;
  }
  .comingdiv ul{
    margin-left: 0px;
}

.About div{
    text-align: center;
}
}