.Burger{

    height: auto;
    overflow-x:unset;
    position: relative;
}

.react-parallax-content{
    padding: 50px 0;
}

.carrouselDiv{
    text-align: center;
    max-height: 800px;

}

.carrouselDiv h1{
    line-height: 0.8;
   
}

.carrouselDiv h3{
    line-height: 0.8;
    margin-top: -30px;
    color: var(--whiteColor);
}

.Slider2Div{
    width: 80%;
    height: 100vh;
    opacity: 1;
    margin: auto;
}

.css-1qzevvg{
    position: relative;
    display: flex;
    height: 60px;
    margin: -27rem auto 0px !important;
    width: 100% !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    }

.css-1qzevvg img{
    width: 4vw;
    height: 4vw !important;
}

.css-1fzpoyk img{
    width: 60vw !important;
}

.movilBurger{
    display: none;
}

.cajitafila{
    position: absolute;
    z-index: 1;
    bottom: -100px;
    width: 100%;
    pointer-events: none;
}

@media (max-width: 768px) {
    .Slider2Div{
        display: none;
    }
    .Burger{
        height: auto;
        
    }
    .movilBurger{
        display: block;
        text-align: center;
      
    }
    .movilBurger img{
    width: 60vw;
    }
    .movilBurger h3{
        font-size: 5vw;
        display: block;
        text-align: center;
        color: var(--whiteColor);
    }

    .cajitafila{
        bottom: -60px;

    }
  }

  @media (max-width: 576px) {
    .cajitafila{
        bottom: -30px;

    }
  }
