*{
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "AxeHandel";
    src: url("/src/fonts/AxeHandel.ttf");
}

/*---------------- Global Styles------------------- */
:root{
    --generalFont: 'Montserrat', sans-serif;
    --titleFont: 'AxeHandel', sans-serif;                                          
    --colorRed: #c91d00;
    --outline: rgb(141, 27, 27);
    --yellowColor: rgb(231, 181, 15);
    --background: #131524;
    --background2: #131524;
    --whiteColor: #e7e6e6;
}


section {
    width: 98.9vw;
    height: 100vh;
    overflow-x: hidden;
}

body{
    background-color: var(--background) ;
    font-family: var(--generalFont);
    overflow-x: hidden;
}

h1{
    color: var(--whiteColor);
    font-family: var(--titleFont);
    font-size: 10vw;
    text-align: center;
    text-shadow: #131524 2px 5px;
}

h3{
    font-weight: 1000;
    font-size: 3vw;
    color:  var(--colorRed);
    text-shadow: #131524 2px 5px;
}

p{
    color: var(--whiteColor);
    font-size: 2.5vw;
    font-weight: 500;
    
}

@media (max-width: 992px) {
    p{
        font-size: 28px;
    }
}

@media (max-width: 576px) {
    h1{

        font-size: 18vw;
    }
    h3{
    font-size: 5vw;
}
p{

    font-size: 4.3vw;

}
} 