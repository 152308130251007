.navbar{
    background-color: var(--colorRed);
    border-radius: 18px;
    outline: 8px solid var(--outline);
    outline-offset: -5px;
    max-width: 90vw;
    margin: auto;
    margin-top: 50px;
   
}

.nav-link{
    text-decoration: none;
    color: var(--whiteColor) !important;
    font-size: 1.5vw;
    font-weight: 700;
    text-align: end;
}

.navbar img{
    position: absolute;
    width: 6vw;
    min-width: 100px;
    left: -20px;
}

.navbar-toggler{
    margin-left: 85%;
}

.nav-item{
   margin: 0 1.4vw;
}

@media (max-width: 1200px) { 

    .nav-item{
        margin: 0 0.5vw;
    }
    .nav-link{

        font-size: 20px;

    }
 }
