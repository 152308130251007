
.tokenomic{
    position: relative;
   overflow-x: unset;
    padding-bottom: 60px;
    height: auto;
    background-color: var(--background2);
}

.tokenomic h1{
    margin-bottom: 100px;
    margin-top: 50px;
}



.ruleta{
    width: 30vw;
    height: 30vw;

  max-width: 500px;
  max-height: 500px;
  min-width: 250px;
  min-height: 250px;
    animation: rotation 10s infinite linear;
}

.ruleta:hover{
    animation-play-state: paused;
}


@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }


.parts{
    margin: 40px;
    margin-bottom: 40px;
    transition: transform 200ms ease-in-out;
}

.parts img{
    width: 20vw;
    max-width: 400px;
    min-width: 300px;
}

.part {
    margin-top: -50px;
    transition: transform 200ms ease-in-out;
    
}
.part:hover{
    z-index: 10;
    transform: scale(1.1);
}

.parts:hover{
    transform: scale(1.05);
}

#part1{z-index: 10;}
#part2{z-index: 9;}
#part3{z-index: 8;}
#part4{z-index: 7;}
#part5{z-index: 6;}
#part6{z-index: 5;}
#part7{z-index: 4;}
#part8{z-index: 3;}
#part9{z-index: 2;}
#part10{z-index:1;}

.tlp{
    font-size: 30px;

}

.token{
    z-index: 1;
    width: 14vw;
    position: absolute;
    min-width: 160px;
}

#token0{
    animation: rotationToken 3s infinite linear;
    top: -25px;
    left: 3vw;
}

#token1{
    animation: rotationToken 3s infinite linear;
    top: 10vh;
    left: -4vw;
}
#token2{
    animation: rotationToken 3s infinite linear;
    top: 30vh;
    left: 2vw;
}
#token3{
    animation: rotationToken 2s infinite linear;
    top: 55vh;
    left: -4vw;
}
#token4{
    animation: rotationToken 4s infinite linear;
    top: 80vh;
    left: 0;
}
#token5{
    animation: rotationToken 4s infinite linear;
    top: 105vh;
    left: -5px;
}

#token6{
    animation: rotationToken 3s infinite linear;
    top: -25px;
    right: 3vw;
}

#token7{
    animation: rotationToken 3s infinite linear;
    top: 10vh;
    right: -4vw;
}
#token8{
    animation: rotationToken 5s infinite linear;
    top: 30vh;
    right: 1vw;
}
#token9{
    animation: rotationToken 2s infinite linear;
    top: 55vh;
    right: -2vw;
}
#token10{
    animation: rotationToken 4s infinite linear;
    top: 80vh;
    right: 1vw;
}
#token11{
    animation: rotationToken 4s infinite linear;
    top: 105vh;
    right: 0.5vw;
}

@keyframes rotationToken {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 768px) {
    .tokenomic{
        height: auto;
        overflow-x: hidden;
    }

    
    .token{
        display: none;
    }
  }

  @media (max-width: 576px) {
    .tokenomic{
    pointer-events: none;
    }
  }