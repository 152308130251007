.Team{
    position: relative;
    height: auto;
   
    padding-bottom: 50px;
    background-color: var(--background2);
}

.Team h1{
    margin: 80px 0;
}

#mustard-1{
    z-index: 1;
    position: absolute;
    right: 15px;
    top: 0;
    width: 30vw;
    max-width: 300px;
    transform: rotate(210deg);
}

#mustard-2{
    width: 30vw;
    z-index: 1;
    position: absolute;
    left: 15px;
    max-width: 300px;
    top: 0;
    transform: rotate(160deg);
}


.Team .col-lg-3{
    margin-bottom: 80px;
    margin-top: 20px;
    
}
.Team h1{
    font-weight: 100;
}

.Team h3{
    font-weight: 100;
    font-size: 40px;
    text-align: center;
    color: white;
    font-family: var(--titleFont);
}

.Team p{
    font-size: 30px;
    text-align: center;
    font-family: var(--titleFont);
    font-weight: 100;
}


.Team img{
    width: 300px;
}

.Team i{
    font-size:  30px;
    text-decoration: none;
    color: white;
    margin: 8px;
}