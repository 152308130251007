.Banner{
    height: 90vh;
    overflow-x: unset;
}

.bannerall{
    margin: auto;
    margin-top: 5%;
}



.Banner h1{
    line-height: 0.8;
    font-size: 16vw;
}

.Banner img{
    margin-top: -16vh;
    width: 40vw;
    animation: float 3s ease-in-out infinite;
    pointer-events: none;
    z-index: -1;
}


@keyframes float {
	0% {

		transform: translatey(0px);
	}
	50% {

		transform: translatey(-40px);
	}
	100% {

		transform: translatey(0px);
	}
}

.Banner button{
    font-size: 1.5vw;
    padding: 5px 20px;
    margin: 5px;
    width: 20vw;
    border-radius: 15px;
    background-color:  var(--colorRed);
    color: var(--whiteColor);
    outline: 4px solid var(--outline);
    outline-offset: -4px;
    z-index: 10;
}

@media (max-width: 1200px) { 
    .bannerall{
        margin-top: 10%;;
    }
    .Banner img{
        margin-top: -15vh;
    }
    
 }

 @media (max-width: 992px) { 
    .Banner{
        height: auto;
    }

    .bannerall{
        margin-top: 5%;;
    }
    .Banner h1{
        text-align: center;
    }
    .Banner h3{
        text-align: center;
    }
    .Banner img{
        margin-top: 0vh;

    }
    
 }

 @media (max-width: 992px) {
    .Banner button{
        font-size: 15px;
        width: 100%;

    }
  }
