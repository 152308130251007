
.roadmap{
    position: relative;
    height: 160vh;


}

.roadmap h1{
    margin-top: 10%;
}

.roadtitle{
    text-align: center;
}



.cajitaRoadmap{
    position: absolute;
    width: 200px;
}

.cajitaRoadmap h3{
    line-height: 0.8;
    margin-right: 2px;
    color: var(--whiteColor);
    text-align: end;
    font-size: 16px;
}

.cajitaRoadmap img{
    width: 140px;
    animation: boxfloat 3s ease-in-out infinite;
    
}
.cajitaRoadmap img:hover{
    filter:saturate(2);
    
}

@keyframes boxfloat {
	0% {

		transform: translatey(0px);
	}
	50% {

		transform: translatey(-40px);
	}
	100% {

		transform: translatey(0px);
	}
}

#RoadBox0{bottom: 10px;left: 10px;}
#RoadBox1{top: 60px;left: 60px;}
#RoadBox2{top: 200px;left: 300px;}
#RoadBox3{bottom: 50px;left: 400px;}
#RoadBox4{bottom: 120px;right: 0px;}
#RoadBox5{top: 50px;right: 0px;}


#RoadBox0-xs{top: 50px;left: 10px;}
#RoadBox1-xs{bottom: 0px;left: 60px;}
#RoadBox2-xs{top: 250px;left: 200px;}
#RoadBox3-xs{top: 50px;left: 300px;}
#RoadBox4-xs{bottom: 300px;right: 0px;}
#RoadBox5-xs{bottom: 50px;right: 0px;}



.mapcol ul{
    list-style-type: none;
    border-left: solid 5px var(--yellowColor);
    padding-left: 2px;
    font-family: var(--titleFont);
    text-align: start;
    font-size: 13px;
    
}
.popover{
    border: solid white 5px;
    
   
}

.popover-header{
    background-color: #000;
    color: white;
    font-size: 200%;
    width: 20vw;
    max-width: 265px;
    
}

.popover-body{
    background-color: #000;
    color: white;
    width: 20vw;
    max-width: 265px;
}


.popover-body li{
    margin: 5px;
}



.topath{
   
    position: absolute;
    width: 655px;
    height: 655px;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-image: url("../images/Line1.png");
    background-size: cover;
    background-repeat: no-repeat;
    border: white 2px solid;
    border-radius: 20px;
    display: none;
}

.topathXL{
   
    position: absolute;
    width: 900px;
    height: 655px;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-image: url("../images/LineXL.png");
    background-size: cover;
    background-repeat: no-repeat;
    border: white 2px solid;
    border-radius: 20px;
    padding-left: 7%;
   
}

.topathXS{
    display: none;
}

.moto{
    z-index: -4;
    width: 200px;
    height: 200px;
    top: 120px;
    background-image: url("../images/moto.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
   transform: scale(.4);
   offset-path: path("M-0.760166 156.924C25.9715 93.7474 152.769 -132.497 152.669 117.644C152.481 584.421 207.353 540.048 264.035 528.278C376.436 559.144 304.158 39.6778 407.38 12.9585C469.108 2.45457 524.762 33.7863 516.646 463.764C513.842 612.359 637.524 430.216 637.524 430.216");
   animation: move 8s linear infinite;

}

.motoXL{
    z-index: -4;
    margin-left: -98px;
    margin-top: 20px;
    width: 200px;
    height: 200px;
    background-image: url("../images/moto.png");
    background-size: cover;
    background-repeat: no-repeat;
   transform: scale(.4);
   offset-path: path("M-34 607.5C25 654.5 76.5 542.5 76.5 483.499C263.222 -755.124 540.224 1297.15 788.075 264.499C792.099 229.758 853.5 105.5 911 140.5");
   animation: move 8s linear infinite;

}
.moto:before,
.moto:after{
  content: '';
  width:10px;
  height:10px;
  position: absolute;
  top:15px;
  left:-5px;
  border-radius: 50%;
  background-color: #000;
  filter: blur(2px);
}

.motoXL:before,
.motoXL:after{
  content: '';
  width:10px;
  height:10px;
  position: absolute;
  top:15px;
  left:-70px;
  border-radius: 50%;
  background-color: #000;
  filter: blur(2px);
}

@keyframes move{
    100%{offset-distance: 100%;}
}


@media (max-width: 992px) {
    .topathXL{
        display: none;
    }
    .topath{
        display: block;
    }
    .moto{
        display: block;
    }
}

@media (max-width: 768px) {
    .topath{
        display: none;
    }

    .roadmap{
        height: auto;
    }
    .topathXS{
        display: block;
    }
    .cajitaRoadmapxs img{
        width: 40vw;
        max-width: 200px;
    }
    .cajitaRoadmapxs h3{
        font-size:  8vw;
        margin: 0px 20px;
    }
    .popover{
        margin-left: -200px;
    }
    .popover-header{
        background-color: #000;
        color: white;
        font-size: 200%;
        width: 200px;
        max-width: 265px;
        
    }
    .popover-body{
        background-color: #000;
        color: white;
        width: 200px;
        max-width: 265px;
        
    }
}